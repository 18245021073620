import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Menu, MenuItem, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Font } from "@react-pdf/renderer";

export default function HeaderButton() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { categories } = useSelector((state) => state.categoryList);

  const items = categories.filter((item) => item.card === true);
  
  const middleIndex = Math.ceil(items.length / 2);
  const item1 = items.slice(0, middleIndex);
  const item2 = items.slice(middleIndex);

  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}
      >
        <span>
          All Cards
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Menu
        id="two-column-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ padding: "16px" }}
      >
        <Grid container spacing={0} sx={{padding: '10px'}}>
          {/* First Column */}
          <Grid item xs={6}>
            {item1.map((item) => (
              <MenuItem 
                key={item.id} 
                onClick={handleClose}
                component="a"
                href={"/product/" + item.name + "/1"}
                sx={{ fontSize: "16px" }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Grid>
          {/* Second Column */}
          <Grid item xs={6}>
            {item2.map((item) => (
              <MenuItem 
                key={item.id} 
                onClick={handleClose}
                component="a"
                href={"/product/" + item.name + "/1"}
                sx={{ fontSize: "16px" }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Grid>
        </Grid>
      </Menu>
        {/* {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            component="a"
            href={"/product/" + item.name + "/1"}
            sx={{ fontSize: "16px" }}
          >
            {item.name}
          </MenuItem>
        ))} */}
      </Menu>

      <Button color="inherit" href="/product/Birthday/1" sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}>
          <span>
            Birthday Cards
          </span>
      </Button>
      <Button color="inherit" href="/product/Thank%20You/1" sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}>
        <span>
          Thank You
        </span>
      </Button>
      <Button color="inherit" href="/product_spec/Photo/1" sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}>
        <span>
          Photo
        </span>
      </Button>
      <Button color="inherit" href="/product_spec/Kids/1" sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}>
        <span>
          Kids
        </span>
      </Button>
      <Button color="inherit" href="/gifts/page/1" sx={{ textTransform: "none", "& span": { fontWeight: "bold", fontSize: "16px" }}}>
        <span>
          Gifts / Giftcards
        </span>
      </Button>
    </>
  );
}
