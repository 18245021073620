import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Typography, Card, CardContent, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { listCategories } from "../../store/actions/categoryActions";

const Occasions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const { categories } = useSelector((state) => state.categoryList);

  const items = categories.filter((item) => item.card === true);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const handleClick = (occasion) => {
    history.push(`/product/${occasion}/1`);
  };

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === "left" ? -300 : 300;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      <div className="cardFilters">
        <div style={{ padding: 3, textAlign: "center", color: "#007373" }}>
          <Typography variant="h5">
            <strong>SEND A FREE-CARD NOW</strong>
          </Typography>
        </div>

        <div className="scrollContainer" style={{ position: 'relative' }}>
          <IconButton
            aria-label="scroll left"
            onClick={() => scroll("left")}
            style={{ position: 'absolute', left: 0, top: '30%', zIndex: 1 }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <div
            className="filterCardPanel"
            ref={scrollRef}
            style={{
              display: 'flex',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              padding: '0 40px',
              scrollbarWidth: 'none', // For Firefox
              msOverflowStyle: 'none', // For Internet Explorer and Edge
            }}
          >
            {items.map((category, index) => (
              <Card
                key={index}
                className="filterCard"
                onClick={() => handleClick(category.name)}
                style={{ flex: '0 0 auto', margin: '0 12px' }}
              >
                <img src={category.imageUrl} alt="card-filter" width="100%" />
                <CardContent>
                  <Typography
                    variant="body2"
                    component="div"
                    className="cardText"
                  >
                    <span style={{ fontFamily: "sans-serif" }}>
                      <strong>{category.name} Cards</strong>
                    </span>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>

          <IconButton
            aria-label="scroll right"
            onClick={() => scroll("right")}
            style={{ position: 'absolute', right: 0, top: '30%', zIndex: 1 }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </Container>
  );
};

export default Occasions;
